import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withPrefix } from 'gatsby';
import PdpHeroBanner from '@cof/graffiti-alley-spray-cans/organisms/PdpHeroBanner';
import VerticalContentPDP from '@cof/graffiti-alley-spray-cans/atoms/VerticalContentPDP/VerticalContentPDP';
import IconSetPDP from '@cof/graffiti-alley-spray-cans/molecules/IconSetPDP/IconSetPDP';
import IconPDP from '@cof/graffiti-alley-spray-cans/atoms/IconPDP/IconPDP';
import { useCardDetails } from '../../hooks/useCardDetails';
import PdpAccordion from '@cof/graffiti-alley-spray-cans/molecules/PdpAccordion';
import PdpAccordionSection from '@cof/graffiti-alley-spray-cans/molecules/PdpAccordion/PdpAccordionSection';
import StickyBar from '@cof/graffiti-alley-spray-cans/molecules/StickyBar';
import CardArt from '@cof/graffiti-alley-spray-cans/molecules/CardArt';
import CardTile from '@cof/graffiti-alley-spray-cans/atoms/CardTile';
import Loading from '@cof/graffiti-alley-spray-cans/atoms/Loading';
import PanelDisplay from '@cof/graffiti-alley-spray-cans/molecules/PanelDisplay';
import PdpReviewCardList from '@cof/graffiti-alley-spray-cans/molecules/PdpReviewCardList';
import Ratings from '../../components/Ratings/Ratings';

import GuaranteedMastercardLegalContent from '../../components/ModalContent/GuaranteedMastercardLegalContent';
import PdpFees from '../../components/Pdp/PdpFees';
import CardTileLearnMore from '../../components/CardTileLearnMore';
import CardTileSecure from '../../components/CardTileSecure';
import { LayoutPDP, LayoutPDPSection } from '../../components/LayoutPDP';
import FlipCardSet from '../../components/FlipCardSet/FlipCardSet';
import { productApiErrorHandler } from '../../lib/api';
import SingleCtaButton from '../../components/SingleCtaButton';
import { getContentKeyUtil } from '../../lib/utils';

import heroBannerImage from '../../assets/images/gm.png';
import greenCheck from '../../assets/images/green-check.svg';
import pdpIconStepOne from '../../assets/images/pdp-icon-buy.svg';
import pdpIconStepTwo from '../../assets/images/pdp-icon-payments.svg';
import pdpIconStepThree from '../../assets/images/pdp-icon-rewarded.svg';
import pdpIconSupport from '../../assets/images/pdp-icon-support.svg';
import pdpIconChat from '../../assets/images/pdp-icon-chat.svg';
import pdpIconAgent from '../../assets/images/pdp-icon-agent.svg';
import pdpGmTileLarge from '../../assets/images/card-tile-gm-large.png';
import pdpGmTileMedium from '../../assets/images/card-tile-gm-medium.png';
import pdpGmTileSmall from '../../assets/images/card-tile-gm-small.png';
import buildCreditBackground from '../../assets/images/build-credit-background.png';

import verticalContentDesktop from '../../assets/images/vertical-content-desktop.png';
import verticalContentTablet from '../../assets/images/vertical-content-tablet.png';
import verticalContentMobile from '../../assets/images/vertical-content-mobile.png';
import verticalContentDesktopFr from '../../assets/images/vertical-content-fr-desktop.png';
import verticalContentTabletFr from '../../assets/images/vertical-content-fr-tablet.png';
import verticalContentMobileFr from '../../assets/images/vertical-content-fr-mobile.png';
import './guaranteed-mastercard.scss';
import SEO from '../../components/SEO/SEO';

const getContentKey = getContentKeyUtil('pages.pdp-gm');

function getImagePerLanguage(intl, enImage, frImage) {
  return intl.locale === 'fr' ? frImage : enImage;
}

const brand = 'gm';
const singleCtaLink = '/quickcheck/';

const subHeader = (
  <>
    <FormattedMessage id={`pages.pdp-gm.hero.description`} values={{ sup1: <sup>1</sup> }} />
    <b>
      <FormattedMessage id={`pages.pdp-gm.hero.boldText`} />
    </b>
  </>
);

const CardTiles = () => {
  return (
    <div className="card-tiles-container">
      <CardTile containerClass="card-tiles-first-child">
        <h3>
          <FormattedMessage
            id="pages.pdp-gm.features.p1"
            values={{
              sup3: <sup>3</sup>,
            }}
          />
        </h3>
        <img src={pdpGmTileLarge} alt="" className="promotion-card-desktop" />
        <img src={pdpGmTileMedium} alt="" className="promotion-card-tablet" />
        <img src={pdpGmTileSmall} alt="" className="promotion-card-mobile" />
      </CardTile>
      <div className="card-tiles-left-container">
        <CardTile containerClass="card-tiles-second-child">
          <h3>
            <FormattedMessage
              id="pages.pdp-gm.features.p2"
              values={{
                sup4: <sup>4</sup>,
              }}
            />
          </h3>
        </CardTile>
        <CardTile containerClass="card-tiles-third-child">
          <h3>
            <FormattedMessage id="pages.pdp-gm.features.p3" />
          </h3>
        </CardTile>
      </div>
    </div>
  );
};

const reviews = [
  {
    stars: 5,
    text: 'pages.pdp-gm.reviews.t1',
    author: 'pages.pdp-gm.reviews.a1',
  },
  {
    stars: 5,
    text: 'pages.pdp-gm.reviews.t2',
    author: 'pages.pdp-gm.reviews.a2',
  },
  {
    stars: 5,
    text: 'pages.pdp-gm.reviews.t3',
    author: 'pages.pdp-gm.reviews.a3',
  },
];

const ReviewsBanner = ({ intl }) => {
  const formattedReviews = reviews.map((review) => ({
    ...review,
    text: intl.formatMessage({ id: review.text }),
    author: intl.formatMessage({ id: review.author }),
  }));
  return (
    <section className="pdp-reviews-banner">
      <h2>{intl.formatMessage({ id: 'pages.pdp-gm.reviews.title' })}</h2>
      <div className="pdp-reviews-content">
        <div className="pdp-reviews-content-box">
          <p>{intl.formatMessage({ id: 'pages.pdp-gm.reviews.testimonial' })}</p>
          <span className="gray-text testimonial-desktop">
            {intl.formatMessage({ id: 'pages.pdp-gm.reviews.author' })} –{' '}
            {intl.formatMessage({ id: 'pages.pdp-gm.reviews.customer' })}
          </span>
          <span className="gray-text testimonial-mobile">
            {intl.formatMessage({ id: 'pages.pdp-gm.reviews.author' })}
          </span>
          <span className="gray-text testimonial-mobile">
            {intl.formatMessage({ id: 'pages.pdp-gm.reviews.customer' })}
          </span>
        </div>
      </div>
      <PdpReviewCardList reviews={formattedReviews} />
    </section>
  );
};

ReviewsBanner.propTypes = {
  intl: intlShape,
};

const CardRequirements = ({ intl }) => {
  return (
    <section className="card-requirements">
      <div className="left-content">
        <div className="left-content-info">
          <h4>
            <FormattedMessage id="pages.pdp-gm.card-requirements.title" />
          </h4>
          <span>
            <FormattedMessage
              id="pages.pdp-gm.card-requirements.description"
              values={{
                learn: (
                  <a href="#faq" className="card-requirements-learn">
                    <FormattedMessage id="pages.pdp-gm.card-requirements.learn" />
                  </a>
                ),
              }}
            />
          </span>
        </div>
      </div>
      <div className="right-content">
        <ol>
          <li>
            <img src={greenCheck} alt="" />
            <p>{intl.formatMessage({ id: `pages.pdp-gm.card-requirements.p1` })}</p>
            <span>{intl.formatMessage({ id: `pages.pdp-gm.card-requirements.desc1` })}</span>
          </li>
          <li>
            <img src={greenCheck} alt="" />
            <p>{intl.formatMessage({ id: `pages.pdp-gm.card-requirements.p2` })}</p>
            <span>{intl.formatMessage({ id: `pages.pdp-gm.card-requirements.desc2` })}</span>
          </li>
          <li>
            <img src={greenCheck} alt="" />
            <p>{intl.formatMessage({ id: `pages.pdp-gm.card-requirements.p3` })}</p>
            <span>{intl.formatMessage({ id: `pages.pdp-gm.card-requirements.desc3` })}</span>
          </li>
          <li>
            <img src={greenCheck} alt="" />
            <p>{intl.formatMessage({ id: `pages.pdp-gm.card-requirements.p4` })}</p>
            <span>{intl.formatMessage({ id: `pages.pdp-gm.card-requirements.desc4` })}</span>
          </li>
        </ol>
      </div>
    </section>
  );
};

CardRequirements.propTypes = {
  intl: intlShape,
};

const PanelDisplayContent = ({ intl }) => {
  return (
    <PanelDisplay mediaSrc={buildCreditBackground} mediaType="image" mediaOrientation="right">
      <div>
        <p>{intl.formatMessage({ id: `pages.pdp-gm.panel.title` })}</p>
        <span>{intl.formatMessage({ id: `pages.pdp-gm.panel.description` })}</span>
      </div>
    </PanelDisplay>
  );
};

PanelDisplayContent.propTypes = {
  intl: intlShape,
};

const LegalDisclaimer = ({ intl }) => {
  const downloadImage = (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 16.3388V13.9388H6.40002V17.9388H17.6V13.9388H16V16.3388H8.00002Z"
        fill="#255F82"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23431 11.3045L9.36568 10.1732L12 12.8075L14.6343 10.1732L15.7657 11.3045L12 15.0702L8.23431 11.3045Z"
        fill="#255F82"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2 13.9389V6.73889H12.8V13.9389H11.2Z" fill="#255F82" />
    </svg>
  );
  const links = [
    {
      text: 'pages.pdp.legal-disclaimer.documents.document1.text',
      url: 'pages.pdp.legal-disclaimer.documents.document1.url',
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document2.text',
      url: 'pages.pdp.legal-disclaimer.documents.document2.url',
    },
    {
      text: 'pages.pdp.legal-disclaimer.documents.document3.text',
      url: 'pages.pdp.legal-disclaimer.documents.document3.url',
    },
  ];
  return (
    <section id="legal-disclaimer-content">
      <h5>
        <FormattedMessage id="pages.pdp-gm.legal-disclaimer.documents" />
      </h5>
      <div className="links">
        {links &&
          links.map(({ text, url }) => (
            <a key={text} href={withPrefix(intl.formatMessage({ id: url }))} target="_blank" rel="noopener noreferrer">
              <div>{downloadImage}</div>
              <FormattedMessage
                id={text}
                values={{
                  boldText: (
                    <b>
                      <FormattedMessage id={text.replace(/text$/, 'values.boldText')} />
                    </b>
                  ),
                }}
              />
            </a>
          ))}
      </div>
      <PdpAccordionSection key={0} title={getContentKey(intl, `legal-disclaimer.title`)}>
        <GuaranteedMastercardLegalContent brand="gm" intl={intl} />
      </PdpAccordionSection>
    </section>
  );
};

LegalDisclaimer.propTypes = {
  intl: intlShape,
};

const GuaranteedMastercardPDP = injectIntl(({ intl }) => {
  const { isLoading, isError, snippetDetails } = useCardDetails({
    intl,
    brand,
    snippetNames: ['annual-fee', 'purchase', 'cash-advance', 'balance-transfer'],
    options: { type: 'detail' },
    errorHandler: productApiErrorHandler,
    isErrorBoundaried: false,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <LayoutPDP
      containerClass="guaranteed-mastercard-page-container"
      title={intl.formatMessage({ id: `pages.pdp-gm.seo.title` })}
      description={intl.formatMessage({ id: `pages.pdp-gm.seo.meta` })}
    >
      <LayoutPDPSection className="hero-banner-layout-wrapper" role="none">
        <PdpHeroBanner
          titleText={getContentKey(intl, 'hero.title')}
          header={<FormattedMessage id="pages.pdp-gm.hero.h1" values={{ MD: <sup>MD</sup> }} />}
          content={
            <>
              <FormattedMessage id="pages.pdp-gm.hero.description" values={{ sup1: <sup>1</sup> }} />
              <b>
                <FormattedMessage id="pages.pdp-gm.hero.boldText" />
              </b>
            </>
          }
          heroImgAlt={intl.formatMessage({ id: `cards.details.${brand}.image-alt` }) || ''}
          brand={brand}
          heroImg={heroBannerImage}
          subHeader={(props) => <p {...props}>{subHeader}</p>}
          renderButton={(ariaDescribedBy) => (
            <>
              <SingleCtaButton
                ariaDescribedBy={ariaDescribedBy}
                brand="gm"
                url={singleCtaLink}
                ctaClassName={intl.locale === 'fr' ? 'gm-cta gm-cta-fr' : 'gm-cta'}
                shouldDisplaySentencedCase={true}
              />
              <Ratings brand="gm" legal="2" />
            </>
          )}
        />
      </LayoutPDPSection>

      <LayoutPDPSection className="card-tiles-layout-wrapper">
        <CardTiles intl={intl} containerClass="pdp-card-tiles-container" />
      </LayoutPDPSection>

      {isError === false && (
        <LayoutPDPSection className="fees-layout-wrapper pdp-gray-background">
          <PdpFees intl={intl} feeSnippets={snippetDetails} />
        </LayoutPDPSection>
      )}

      <LayoutPDPSection className="icon-set-layout-wrapper pdp-gray-background">
        <div className="pdp-icon-set-container">
          <h2>{getContentKey(intl, 'how-it-works.title')}</h2>
          <IconSetPDP>
            <IconPDP
              caption={getContentKey(intl, 'how-it-works.step1')}
              subCaption={getContentKey(intl, 'how-it-works.p1')}
              icon={pdpIconStepOne}
              iconAlt=""
              bolded="bottom"
            />
            <IconPDP
              caption={getContentKey(intl, 'how-it-works.step2')}
              subCaption={getContentKey(intl, 'how-it-works.p2')}
              icon={pdpIconStepTwo}
              iconAlt=""
              bolded="bottom"
            />
            <IconPDP
              caption={getContentKey(intl, 'how-it-works.step3')}
              subCaption={getContentKey(intl, 'how-it-works.p3')}
              icon={pdpIconStepThree}
              iconAlt=""
              bolded="bottom"
            />
          </IconSetPDP>
        </div>
      </LayoutPDPSection>

      <LayoutPDPSection className="pdp-gray-background">
        <CardRequirements intl={intl} />
      </LayoutPDPSection>

      <LayoutPDPSection className="pdp-gray-background panel-content">
        <PanelDisplayContent intl={intl} />
      </LayoutPDPSection>

      <LayoutPDPSection className="flip-card-set-layout-wrapper pdp-gray-background">
        <FlipCardSet />
      </LayoutPDPSection>

      <LayoutPDPSection className="pdp-gray-background">
        <ReviewsBanner wrapperC intl={intl} />
      </LayoutPDPSection>

      <LayoutPDPSection className="credit-vertical-contents-card-layout-wrapper pdp-gray-background">
        <VerticalContentPDP
          titleHeadingTag="h2"
          titleText={getContentKey(intl, 'vertical.title')}
          content={<FormattedMessage id="pages.pdp-gm.vertical.description" values={{ break: <br /> }} />}
          image={
            window.innerWidth >= 1024
              ? getImagePerLanguage(intl, verticalContentDesktop, verticalContentDesktopFr)
              : window.innerWidth >= 640
              ? getImagePerLanguage(intl, verticalContentTablet, verticalContentTabletFr)
              : getImagePerLanguage(intl, verticalContentMobile, verticalContentMobileFr)
          }
          imageAlt=""
        />
      </LayoutPDPSection>

      <LayoutPDPSection className="card-tile-secure-layout-wrapper pdp-gray-background">
        <CardTileSecure
          titleLeft={intl.formatMessage({ id: 'pages.pdp-gm.card-tiles.c1' })}
          descriptionLeft={intl.formatMessage({ id: 'pages.pdp-gm.card-tiles.p1' })}
          titleRight={intl.formatMessage({ id: 'pages.pdp-gm.card-tiles.c2' })}
          descriptionRight={intl.formatMessage({ id: 'pages.pdp-gm.card-tiles.p2' })}
        />
      </LayoutPDPSection>

      <LayoutPDPSection className="card-tile-learn-more-layout-wrapper pdp-gray-background">
        <CardTileLearnMore brand="gm" />
      </LayoutPDPSection>

      <LayoutPDPSection className="icon-set-layout-wrapper pdp-gray-background">
        <div className="pdp-icon-set-container">
          <h2>{getContentKey(intl, 'support.title')}</h2>
          <IconSetPDP dividers={false}>
            <IconPDP
              caption={getContentKey(intl, 'support.step1')}
              subCaption={getContentKey(intl, 'support.p1')}
              icon={pdpIconSupport}
              iconAlt=""
              bolded="top"
            />
            <IconPDP
              caption={getContentKey(intl, 'support.step2')}
              subCaption={getContentKey(intl, 'support.p2')}
              icon={pdpIconChat}
              iconAlt=""
              bolded="top"
            />
            <IconPDP
              caption={getContentKey(intl, 'support.step3')}
              subCaption={getContentKey(intl, 'support.p3')}
              icon={pdpIconAgent}
              iconAlt=""
              bolded="top"
            />
          </IconSetPDP>
        </div>
      </LayoutPDPSection>

      <LayoutPDPSection className="faq">
        <h2 id="faq" className="faq-title">
          {getContentKey(intl, 'faq.title')}
        </h2>
        <PdpAccordion className="pdp-accordion">
          {Array(4)
            .fill()
            .map((_, i) => {
              const contentIndex = i + 1;
              const faq = getContentKey(intl, `faq.${'q' + contentIndex}`);
              let faqContent;
              if (contentIndex === 1) {
                faqContent = (
                  <>
                    <FormattedMessage id={`pages.pdp-gm.faq.p${i + 1}-1`} />
                    <div className="bullet-points" data-testid="accordion-bullet-points">
                      <FormattedMessage id={`pages.pdp-gm.faq.p${i + 1}-1-1`} values={{ bullet: <>&bull;</> }} />
                      <FormattedMessage
                        id={`pages.pdp-gm.faq.p${i + 1}-1-2`}
                        values={{
                          bullet: (
                            <>
                              <br />
                              &bull;
                            </>
                          ),
                        }}
                      />
                      <FormattedMessage
                        id={`pages.pdp-gm.faq.p${i + 1}-1-3`}
                        values={{
                          bullet: (
                            <>
                              <br />
                              &bull;
                            </>
                          ),
                        }}
                      />
                      <FormattedMessage
                        id={`pages.pdp-gm.faq.p${i + 1}-1-4`}
                        values={{
                          bullet: (
                            <>
                              <br />
                              &bull;
                            </>
                          ),
                        }}
                      />
                    </div>
                  </>
                );
              } else if (contentIndex === 2) {
                faqContent = (
                  <>
                    <p>
                      <FormattedMessage
                        id={`pages.pdp-gm.faq.p${i + 1}-1`}
                        values={{ sup1: <sup>1</sup>, MC: intl.locale === 'fr' ? <sup>MC</sup> : undefined }}
                      />
                    </p>
                    <p>
                      <FormattedMessage id={`pages.pdp-gm.faq.p${i + 1}-2`} values={{ sup1: <sup>1</sup> }} />
                    </p>
                  </>
                );
              } else {
                faqContent = getContentKey(intl, `faq.${'p' + contentIndex}`);
              }
              return (
                <PdpAccordionSection key={i} title={faq}>
                  {faqContent}
                </PdpAccordionSection>
              );
            })}
        </PdpAccordion>
      </LayoutPDPSection>

      <LayoutPDPSection
        className={`legal-disclaimer-layout-wrapper ${intl.locale === 'en' ? 'extra-space-bottom' : ''}`}
      >
        <LegalDisclaimer intl={intl} />
      </LayoutPDPSection>

      <StickyBar contentPosition="center" isVerticalDisplayinSmallScreen={false} stickybarPosition="bottom">
        <CardArt brand={brand} classImageStyle="stickybar-img" />
        <div className="stickybar-button single-cta">
          <SingleCtaButton brand="gm" url={singleCtaLink} ctaClassName="stickybar" />
        </div>
      </StickyBar>
    </LayoutPDP>
  );
});

export default GuaranteedMastercardPDP;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.pdp-gm.seo.title" descriptionKey="pages.pdp-gm.seo.meta" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
